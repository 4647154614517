.experienceCard{
    width: 60rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e2d9f352;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.768);
    font-family: 'Roboto', sans-serif;
    /* border: 1px solid red; */
    padding: 20px;
}

.experienceCard-heading{
    height: 25%;
    /* border: 1px solid red; */
}

.experienceCard-heading h2{
    font-size: 1.7rem;
    font-weight: 600;
    color: #2c1a4d;
}

.experience-gif{
    height: 10rem;
    margin-right: 10px;
    /* border: 1px solid red; */
}

.experienceCard-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 75%;
}

.experienceCard-body-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 60%;
    height: 100%;
}

.experienceCard-body-left{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px solid red; */
    width: 40%;
    height: 100%;
}

.certificates{
    /* border: 1px solid red; */
    width: 80%;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    /* margin-top: 40px; */
}

.certificates a{
    text-decoration: none;
    color: #58359ae9;
    margin: 0 20px;
    font-size: 18px;
}

.certificates a:hover{
    text-decoration: underline;
}

.technologies{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.technologies img,
.technologies svg{
    width: 35px;
    margin: 0 10px;
    transition: all 0.1s ease-in;
    cursor: pointer;
}

.technologies img:hover,
.technologies svg:hover{
    transform: scale(1.1);
}

/* Dark Mode */
.experienceCard-dark{
    /* background-color: #e2d9f300; */
    background-color: #121212;
    color: white;
    border: 1px solid #2c1a4d;
    box-shadow: none;
}
.experienceCard-dark .experienceCard-heading h2{
    color: #6f42c1;
}
.experienceCard-dark h5,
.experienceCard-dark li{
    color: white;
    opacity: 0.87;
}

/* #6f42c1 */

/* Responsive */

@media screen and (max-width:991px){
    .experienceCard{
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 768px){
    .experienceCard{
        width: 100%;
        height: auto;
        border-radius: 0;
        box-shadow: none;
        padding: 5px;
    }
    .experienceCard-body{
        /* height: 100%; */
        width: 100%;
        flex-direction: column;
    }
    .experienceCard-heading{
        height: 50%;
    }
    .experienceCard-heading h2{
        font-size: 1.5rem;
    }
    .experienceCard-body-right{
        width: 100%;
    }
    .experienceCard-body-left{
        width: 100%;
    }
    .certificates{
        width: 100%;
        padding-top: 10px;
        justify-content: center;
    }
    .technologies{
        width: 100%;
    }
}

@media screen and (max-width: 480px){


    .experienceCard-heading h2{
        font-size: 1.3rem;
    }
    .experienceCard-heading h5{
        font-size: 1.1rem;
    }

    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 385px){

    .experienceCard-heading h2{
        font-size: 1.2rem;
    }
    .experienceCard-heading h5{
        font-size: 1rem;
    }
    .experience-gif{
        height: 8rem;
    }

    .technologies{
        flex-wrap: wrap;
    }

    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 320px){

    .experienceCard-heading h2{
        font-size: 1.1rem;
    }
    .experienceCard-heading h5{
        font-size: 1rem;
    }
    .experience-gif{
        height: 7rem;
    }
    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 300px){

    .experienceCard-heading h2{
        font-size: 1.1rem;
    }
    .experienceCard-heading h5{
        font-size: 1rem;
    }
    .experience-gif{
        height: 7rem;
    }


    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}