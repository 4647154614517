.experience{
    display: flex;
    flex-direction:column;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
}


/* Dark Mode */
.experience-dark .underline-animation{
    color: #6f42c1;
}


/* Responsive */

@media (max-width: 768px) {
    .experience{
        flex-direction: column;
        padding: 0;
    }
}
