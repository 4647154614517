.navbar-brand{
    color: #6f42c1;
}
.navbar-brand:hover{
    color: #6f42c1;
}


.collab{
    border: none;
    outline: none;
    color: #6f42c1;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    background-color: #6e42c110;
    padding: 1px 4px;
    border: 1px solid #6e42c110;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
}

.collab:hover{
    border: 1px solid #6f42c1;
    color: #6f42c1;
}

.form-check-input:checked {
    background-color: #6f42c1fd;
    border-color: #6f42c1;
}

.form-check-input:focus{
    box-shadow: none;
}