.projects{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.projectCard{
    padding: 10px;
}

.project-links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.project-links a{
    text-decoration: none;
    color: #432874cc;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: bold;
}
.project-links a:hover{
    color: #432874;
}

/* Dark Mode */

.projects-dark .card{
    border: none;
    /* background-color: #43287439; */
    background-color: #121212;
    border: 1px solid #2c1a4d;
    color: white;
    opacity: 0.87;
}

.projects-dark .project-links a{
    color: #816baa;
}
.projects-dark .underline-animation{
    color: #6f42c1;
}
