.heading {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 2.5rem 0;
}

.underline-animation {
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.768);
}

.underline-animation:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #58359ae9;
    transition: all 3s ease-in-out;
    animation: moving-line 1.5s infinite;
}

@keyframes moving-line {
    0%{
        left: 10%;
    }
    10%{
        left: 25%;
    }
    20%{
        left: 35%;
    }
    30%{
        left: 45%;
    }
    40%{
        left: 55%;
    }
    50%{
        left: 65%;
    }
    60%{
        left: 45%;
    }
    70%{
        left: 35%;
    }
    80%{
        left: 25%;
    }
    90%{
        left: 15%;
    }
    100%{
        left: 0%;
    }
}