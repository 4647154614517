
.skills{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 70vh;
    padding: 20px;
    /* border: 1px solid red; */
}

.skills-dark{
    color: white;
}

.skill-img-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;
     /* padding:0 5px 0 15px;    */
}

.skill-img-container p{
    margin-top: 10px;
}

.skills img{
    height: 5rem;
    /* margin: 40px; */
    cursor: pointer;
    transition: all 0.2s ease-in;

   

    -webkit-filter: drop-shadow(5px 5px 4px#222);
    filter: drop-shadow(5px 5px 4px #222);
    
}

.skills img:hover{
    transform: scale(1.1);
    -webkit-filter: drop-shadow(5px 5px 5px#222);
    filter: drop-shadow(5px 5px 5px #222);
}

/* Dark Mode */
.skills-dark .skills img{
    -webkit-filter: drop-shadow(0px 0px 0px#222);
    filter: drop-shadow(0px 0px 0px #222);
}
.skills-dark .underline-animation{
    color: #6f42c1;
}


/* Responsive */

@media screen and (max-width:425px){

    .skills{
        padding: 0;
    }
    .skills .skill-img-container{
        margin: 0;
    }
    .skills img{
        margin: 30px;
    }
}

@media screen and (max-width:345px){
    .skills img{
        margin: 30px 20px;
    }
}

@media screen and (max-width:295){
    .skills img{
        margin: 30px 5px;
    }
}