body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar-brand{
    color: #6f42c1;
}
.navbar-brand:hover{
    color: #6f42c1;
}


.collab{
    border: none;
    outline: none;
    color: #6f42c1;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    background-color: #6e42c110;
    padding: 1px 4px;
    border: 1px solid #6e42c110;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
}

.collab:hover{
    border: 1px solid #6f42c1;
    color: #6f42c1;
}

.form-check-input:checked {
    background-color: #6f42c1fd;
    border-color: #6f42c1;
}

.form-check-input:focus{
    box-shadow: none;
}


body {
    /* position: relative; */
    
}
.bg-dark{
    background-image: url('/images/code_bg3.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

}



.home{
    margin-top: 57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 90vh;
}

.home-left{
    width: 55%;
    height: 100%;
    display: flex;
    align-items: center;
}

.home-left .name-section{
    width: 100%;
    color: rgba(0, 0, 0, 0.768);
}

.name-section .name{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1rem;
}


.moving-text{
    width: 100%;
}

.home-left .moving-text p{
    color: rgba(0, 0, 0, 0.768);
    font-size: 3.5rem;
    display: inline-block;
    
}
.home-left .moving-text span{
    color: #58359ae9;
    font-size: 3.2rem;
    display: inline-block;
}


.home-btn-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
}

.home-btn-section .btn{
    width: 12rem;
    height: 3rem;
    /* border: 1px solid red; */
    background-color: #58359ae9;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.home-btn-section svg{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    fill: white;
}


.home-right{
    /* border: 1px solid blue; */
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-img-container{
    width: 100%;
    /* width: 20rem;
    height: 20rem; */
    /* border-radius: 50%; */
    /* border: 3px solid #58359ae9; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #58359a64; */
}

.home-img-container img{
    width: 100%;
    /* height: 70%; */
    height: 100%;
    /* border-radius: 50%; */
    /* border: 1px solid red; */
}

.buymeacoffe img{
    width:163px;
    height: 45px;
    border: 2px solid #58359a;
    border-radius: 8px;
}


/* Dark Mode */
.home-dark .home-left .name-section,
.home-dark .home-left .moving-text p{
    color: white;
    opacity: 0.87;
}

.home-dark .home-left .moving-text span{
    color: #6f42c1;
}

.home-dark .home-img-container{
    /* border: 3px solid #6f42c1; */
    /* #6f42c1 */
}


/* Responsive */

@media (max-width: 991px){
    .home-left{
        width: 100%;
    }
    .home-right{
        width: 100%;
    }
    .name-section .name{
        font-size: 2.5rem;
    }    
    .home-left .moving-text p{
        font-size: 1.8rem;
    }
    .home-left .moving-text span{
        font-size: 1.8rem;
    }

    .home-btn-section{
        justify-content: center;
    }
    
}


@media (max-width: 768px) {
    .home{
        flex-direction: column;
        justify-content: space-evenly;
    }
    .home-left{
        width: 100%;
        order: 1;
    }

    .home-right{
        padding-top: 20px;
    }

    .name-section .name{
        font-size: 2.6rem;
    }
    
    .home-left .moving-text p{
        font-size: 1.8rem;
    }
    .home-left .moving-text span{
        font-size: 1.8rem;
    }
    
    .home-right{
        width: 100%;
    }
    .home-img-container{
        /* width: 15rem; */
        /* height: 15rem; */
        height: 100%;
        width: 100%;
    }

    .home-btn-section .btn{
        margin-right: 0;
        margin: 0 2px;
    }
}


@media (max-width: 576px) {
    .home{
        flex-direction: column;
    }
    .home-left{
        width: 100%;
        order: 1;
    }

    .name-section .name{
        font-size: 2.2rem;
    }
    
    .home-left .moving-text p{
        font-size: 1.5rem;
    }
    .home-left .moving-text span{
        font-size: 1.5rem;
    }
    
    .home-img-container{
        /* width: 12rem;
        height: 12rem; */
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 425px) {
    .home{
        flex-direction: column;
    }
    .home-left{
        width: 100%;
        order: 1;
    }

    .name-section .name{
        font-size: 1.8rem;
    }
    
    .home-left .moving-text p{
        font-size: 1.2rem;
    }
    .home-left .moving-text span{
        font-size: 1.2rem;
    }
    
    .home-img-container{
        /* width: 10rem;
        height: 10rem; */
        height: 100%;
        width: 100%;
    }
}
.about{
    /* min-height: 90vh; */
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    padding: 10px 0;
}

.about-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 50%;
}

.about-img-container{
    width: 60%;
    height: 60%;
    /* border: 1px solid red; */

    /* width: 100%; */
    /* width: 24rem; */
    /* height: 24rem; */
    /* border-radius: 50%; */
    /* border: 3px solid #58359ae9; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: #58359a64; */
}

.about-img-container img{
    width: 100%;
    height: 100%;
    /* width: 100%; */
    /* height: 100%; */
    /* border-radius: 100%; */
    /* border: 1px solid red; */
}

.about-right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid red; */
    width: 50%;
}

.about-right p{
    font-size: 1.07rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.768);
    font-family: 'Roboto', sans-serif;
    margin-top: 2rem;
    text-align:justify;

}

.about-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.about-btn-container .btn{
    width: 12rem;
    height: 3rem;
    background-color: #58359a1f;
    border: 2px solid#58359ae9;
    color: #58359ae9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    transition: all 0.1s ease-in;
}

.about-btn-container .btn:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

/* Dark Mode */
.about-dark .about-right p{
    color: white;
    opacity: 0.87;
}

.about-dark .about-btn-container .btn{
    background-color: #58359ae9;
    color: white;
}
.about-dark .underline-animation{
    color: #6f42c1;
}



/* Responsive */

@media (max-width: 991px){
    .about{
        flex-direction: column;
    }

    .about-left{
        width: 100%;
    }

    .about-right{
        width: 100%;
    }
}

@media screen and (max-width: 768px){
    .about-right{
        padding: 0 5px;
    }
    .about-right p{
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 576px){
    .about-right{
        padding: 0 8px;
    }
    .about-right p{
        font-size: 1rem;
    }
    .about-btn-container .btn{
        width: 10rem;
        height: 2.5rem;
        font-size: 1.2rem;
    }
}
.heading {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 2.5rem 0;
}

.underline-animation {
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.768);
}

.underline-animation:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #58359ae9;
    transition: all 3s ease-in-out;
    -webkit-animation: moving-line 1.5s infinite;
            animation: moving-line 1.5s infinite;
}

@-webkit-keyframes moving-line {
    0%{
        left: 10%;
    }
    10%{
        left: 25%;
    }
    20%{
        left: 35%;
    }
    30%{
        left: 45%;
    }
    40%{
        left: 55%;
    }
    50%{
        left: 65%;
    }
    60%{
        left: 45%;
    }
    70%{
        left: 35%;
    }
    80%{
        left: 25%;
    }
    90%{
        left: 15%;
    }
    100%{
        left: 0%;
    }
}

@keyframes moving-line {
    0%{
        left: 10%;
    }
    10%{
        left: 25%;
    }
    20%{
        left: 35%;
    }
    30%{
        left: 45%;
    }
    40%{
        left: 55%;
    }
    50%{
        left: 65%;
    }
    60%{
        left: 45%;
    }
    70%{
        left: 35%;
    }
    80%{
        left: 25%;
    }
    90%{
        left: 15%;
    }
    100%{
        left: 0%;
    }
}

.skills{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 70vh;
    padding: 20px;
    /* border: 1px solid red; */
}

.skills-dark{
    color: white;
}

.skill-img-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px;
     /* padding:0 5px 0 15px;    */
}

.skill-img-container p{
    margin-top: 10px;
}

.skills img{
    height: 5rem;
    /* margin: 40px; */
    cursor: pointer;
    transition: all 0.2s ease-in;

   

    -webkit-filter: drop-shadow(5px 5px 4px#222);
    filter: drop-shadow(5px 5px 4px #222);
    
}

.skills img:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: drop-shadow(5px 5px 5px#222);
    filter: drop-shadow(5px 5px 5px #222);
}

/* Dark Mode */
.skills-dark .skills img{
    -webkit-filter: drop-shadow(0px 0px 0px#222);
    filter: drop-shadow(0px 0px 0px #222);
}
.skills-dark .underline-animation{
    color: #6f42c1;
}


/* Responsive */

@media screen and (max-width:425px){

    .skills{
        padding: 0;
    }
    .skills .skill-img-container{
        margin: 0;
    }
    .skills img{
        margin: 30px;
    }
}

@media screen and (max-width:345px){
    .skills img{
        margin: 30px 20px;
    }
}

@media screen and (max-width:295){
    .skills img{
        margin: 30px 5px;
    }
}
.experience{
    display: flex;
    flex-direction:column;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
}


/* Dark Mode */
.experience-dark .underline-animation{
    color: #6f42c1;
}


/* Responsive */

@media (max-width: 768px) {
    .experience{
        flex-direction: column;
        padding: 0;
    }
}

.experienceCard{
    width: 60rem;
    height: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e2d9f352;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.768);
    font-family: 'Roboto', sans-serif;
    /* border: 1px solid red; */
    padding: 20px;
}

.experienceCard-heading{
    height: 25%;
    /* border: 1px solid red; */
}

.experienceCard-heading h2{
    font-size: 1.7rem;
    font-weight: 600;
    color: #2c1a4d;
}

.experience-gif{
    height: 10rem;
    margin-right: 10px;
    /* border: 1px solid red; */
}

.experienceCard-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 100%;
    height: 75%;
}

.experienceCard-body-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 60%;
    height: 100%;
}

.experienceCard-body-left{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* border: 1px solid red; */
    width: 40%;
    height: 100%;
}

.certificates{
    /* border: 1px solid red; */
    width: 80%;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    /* margin-top: 40px; */
}

.certificates a{
    text-decoration: none;
    color: #58359ae9;
    margin: 0 20px;
    font-size: 18px;
}

.certificates a:hover{
    text-decoration: underline;
}

.technologies{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.technologies img,
.technologies svg{
    width: 35px;
    margin: 0 10px;
    transition: all 0.1s ease-in;
    cursor: pointer;
}

.technologies img:hover,
.technologies svg:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

/* Dark Mode */
.experienceCard-dark{
    /* background-color: #e2d9f300; */
    background-color: #121212;
    color: white;
    border: 1px solid #2c1a4d;
    box-shadow: none;
}
.experienceCard-dark .experienceCard-heading h2{
    color: #6f42c1;
}
.experienceCard-dark h5,
.experienceCard-dark li{
    color: white;
    opacity: 0.87;
}

/* #6f42c1 */

/* Responsive */

@media screen and (max-width:991px){
    .experienceCard{
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 768px){
    .experienceCard{
        width: 100%;
        height: auto;
        border-radius: 0;
        box-shadow: none;
        padding: 5px;
    }
    .experienceCard-body{
        /* height: 100%; */
        width: 100%;
        flex-direction: column;
    }
    .experienceCard-heading{
        height: 50%;
    }
    .experienceCard-heading h2{
        font-size: 1.5rem;
    }
    .experienceCard-body-right{
        width: 100%;
    }
    .experienceCard-body-left{
        width: 100%;
    }
    .certificates{
        width: 100%;
        padding-top: 10px;
        justify-content: center;
    }
    .technologies{
        width: 100%;
    }
}

@media screen and (max-width: 480px){


    .experienceCard-heading h2{
        font-size: 1.3rem;
    }
    .experienceCard-heading h5{
        font-size: 1.1rem;
    }

    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 385px){

    .experienceCard-heading h2{
        font-size: 1.2rem;
    }
    .experienceCard-heading h5{
        font-size: 1rem;
    }
    .experience-gif{
        height: 8rem;
    }

    .technologies{
        flex-wrap: wrap;
    }

    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 320px){

    .experienceCard-heading h2{
        font-size: 1.1rem;
    }
    .experienceCard-heading h5{
        font-size: 1rem;
    }
    .experience-gif{
        height: 7rem;
    }
    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 300px){

    .experienceCard-heading h2{
        font-size: 1.1rem;
    }
    .experienceCard-heading h5{
        font-size: 1rem;
    }
    .experience-gif{
        height: 7rem;
    }


    .technologies img,
    .technologies svg{
        width: 30px;
        margin: 0 5px;
    }
}
.projects{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}

.projectCard{
    padding: 10px;
}

.project-links{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.project-links a{
    text-decoration: none;
    color: #432874cc;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: bold;
}
.project-links a:hover{
    color: #432874;
}

/* Dark Mode */

.projects-dark .card{
    border: none;
    /* background-color: #43287439; */
    background-color: #121212;
    border: 1px solid #2c1a4d;
    color: white;
    opacity: 0.87;
}

.projects-dark .project-links a{
    color: #816baa;
}
.projects-dark .underline-animation{
    color: #6f42c1;
}

#openSource img{
    width: 940px;
    height: 300px;
}

#openSource{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.openSource-dark h2{
    color: #6f42c1;
}
.contact{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.contact-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    font-size: 1.2rem;
    text-decoration: none;
    color: black;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    padding: 10px;
    margin: 10px 30px;
}

.contact-item:hover{
    background-color: #58359a35;
    color: black;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.contact-item svg{
    /* width: 3rem; */
    height: 4rem;
    fill:#58359ae9;
}


/* Dark Mode */
.contact-dark .contact-item{
    color: white;
    opacity: 0.87;
}

.contact-dark .contact-item svg{
    fill:#6f42c1;
}
.contact-dark .underline-animation{
    color: #6f42c1;
}

