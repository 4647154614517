#openSource img{
    width: 940px;
    height: 300px;
}

#openSource{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.openSource-dark h2{
    color: #6f42c1;
}