.about{
    /* min-height: 90vh; */
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    padding: 10px 0;
}

.about-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    width: 50%;
}

.about-img-container{
    width: 60%;
    height: 60%;
    /* border: 1px solid red; */

    /* width: 100%; */
    /* width: 24rem; */
    /* height: 24rem; */
    /* border-radius: 50%; */
    /* border: 3px solid #58359ae9; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: #58359a64; */
}

.about-img-container img{
    width: 100%;
    height: 100%;
    /* width: 100%; */
    /* height: 100%; */
    /* border-radius: 100%; */
    /* border: 1px solid red; */
}

.about-right{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid red; */
    width: 50%;
}

.about-right p{
    font-size: 1.07rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.768);
    font-family: 'Roboto', sans-serif;
    margin-top: 2rem;
    text-align:justify;

}

.about-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.about-btn-container .btn{
    width: 12rem;
    height: 3rem;
    background-color: #58359a1f;
    border: 2px solid#58359ae9;
    color: #58359ae9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    transition: all 0.1s ease-in;
}

.about-btn-container .btn:hover{
    transform: scale(1.05);
}

/* Dark Mode */
.about-dark .about-right p{
    color: white;
    opacity: 0.87;
}

.about-dark .about-btn-container .btn{
    background-color: #58359ae9;
    color: white;
}
.about-dark .underline-animation{
    color: #6f42c1;
}



/* Responsive */

@media (max-width: 991px){
    .about{
        flex-direction: column;
    }

    .about-left{
        width: 100%;
    }

    .about-right{
        width: 100%;
    }
}

@media screen and (max-width: 768px){
    .about-right{
        padding: 0 5px;
    }
    .about-right p{
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 576px){
    .about-right{
        padding: 0 8px;
    }
    .about-right p{
        font-size: 1rem;
    }
    .about-btn-container .btn{
        width: 10rem;
        height: 2.5rem;
        font-size: 1.2rem;
    }
}