

body {
    /* position: relative; */
    
}
.bg-dark{
    background-image: url('/images/code_bg3.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

}



.home{
    margin-top: 57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 90vh;
}

.home-left{
    width: 55%;
    height: 100%;
    display: flex;
    align-items: center;
}

.home-left .name-section{
    width: 100%;
    color: rgba(0, 0, 0, 0.768);
}

.name-section .name{
    font-size: 3rem;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1rem;
}


.moving-text{
    width: 100%;
}

.home-left .moving-text p{
    color: rgba(0, 0, 0, 0.768);
    font-size: 3.5rem;
    display: inline-block;
    
}
.home-left .moving-text span{
    color: #58359ae9;
    font-size: 3.2rem;
    display: inline-block;
}


.home-btn-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
}

.home-btn-section .btn{
    width: 12rem;
    height: 3rem;
    /* border: 1px solid red; */
    background-color: #58359ae9;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.home-btn-section svg{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    fill: white;
}


.home-right{
    /* border: 1px solid blue; */
    width: 45%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-img-container{
    width: 100%;
    /* width: 20rem;
    height: 20rem; */
    /* border-radius: 50%; */
    /* border: 3px solid #58359ae9; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #58359a64; */
}

.home-img-container img{
    width: 100%;
    /* height: 70%; */
    height: 100%;
    /* border-radius: 50%; */
    /* border: 1px solid red; */
}

.buymeacoffe img{
    width:163px;
    height: 45px;
    border: 2px solid #58359a;
    border-radius: 8px;
}


/* Dark Mode */
.home-dark .home-left .name-section,
.home-dark .home-left .moving-text p{
    color: white;
    opacity: 0.87;
}

.home-dark .home-left .moving-text span{
    color: #6f42c1;
}

.home-dark .home-img-container{
    /* border: 3px solid #6f42c1; */
    /* #6f42c1 */
}


/* Responsive */

@media (max-width: 991px){
    .home-left{
        width: 100%;
    }
    .home-right{
        width: 100%;
    }
    .name-section .name{
        font-size: 2.5rem;
    }    
    .home-left .moving-text p{
        font-size: 1.8rem;
    }
    .home-left .moving-text span{
        font-size: 1.8rem;
    }

    .home-btn-section{
        justify-content: center;
    }
    
}


@media (max-width: 768px) {
    .home{
        flex-direction: column;
        justify-content: space-evenly;
    }
    .home-left{
        width: 100%;
        order: 1;
    }

    .home-right{
        padding-top: 20px;
    }

    .name-section .name{
        font-size: 2.6rem;
    }
    
    .home-left .moving-text p{
        font-size: 1.8rem;
    }
    .home-left .moving-text span{
        font-size: 1.8rem;
    }
    
    .home-right{
        width: 100%;
    }
    .home-img-container{
        /* width: 15rem; */
        /* height: 15rem; */
        height: 100%;
        width: 100%;
    }

    .home-btn-section .btn{
        margin-right: 0;
        margin: 0 2px;
    }
}


@media (max-width: 576px) {
    .home{
        flex-direction: column;
    }
    .home-left{
        width: 100%;
        order: 1;
    }

    .name-section .name{
        font-size: 2.2rem;
    }
    
    .home-left .moving-text p{
        font-size: 1.5rem;
    }
    .home-left .moving-text span{
        font-size: 1.5rem;
    }
    
    .home-img-container{
        /* width: 12rem;
        height: 12rem; */
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 425px) {
    .home{
        flex-direction: column;
    }
    .home-left{
        width: 100%;
        order: 1;
    }

    .name-section .name{
        font-size: 1.8rem;
    }
    
    .home-left .moving-text p{
        font-size: 1.2rem;
    }
    .home-left .moving-text span{
        font-size: 1.2rem;
    }
    
    .home-img-container{
        /* width: 10rem;
        height: 10rem; */
        height: 100%;
        width: 100%;
    }
}