.contact{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.contact-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    font-size: 1.2rem;
    text-decoration: none;
    color: black;
    border-radius: 5px;
    transition: all 0.2s ease-in;
    padding: 10px;
    margin: 10px 30px;
}

.contact-item:hover{
    background-color: #58359a35;
    color: black;
    transform: scale(1.05);
}

.contact-item svg{
    /* width: 3rem; */
    height: 4rem;
    fill:#58359ae9;
}


/* Dark Mode */
.contact-dark .contact-item{
    color: white;
    opacity: 0.87;
}

.contact-dark .contact-item svg{
    fill:#6f42c1;
}
.contact-dark .underline-animation{
    color: #6f42c1;
}
